

























































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.icon-Aula_plus.in-circle {
  color: $color-white;
  .theme-employee & {
    background-color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    background-color: $color-primary-darker-guardian;
  }
  .theme-child & {
    background-color: $color-primary-darker-child;
  }
  .theme-admin & {
    background-color: $color-primary-darker-admin;
  }
}
.icon-Aula_attach_doc {
  font-size: 19px;
  line-height: 15px;
  position: relative;
  top: 3px;

  .tool-link & {
    font-size: 19px;
  }
}

.documents-toolbar {
  font-size: 14px;
  background-color: $color-grey-base;
  height: 50px;
  padding-left: 0;
  outline: none;

  .navbar-nav {
    flex-direction: row;
    gap: 4px;
  }
}
