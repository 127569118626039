
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/documents/_overview.scss';

.restore-icon {
  --font-size: 1.8rem;
}

.restore-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 0;
  width: 100px;

  .restore-text {
    margin-top: 4px;
    font-weight: 400;
    text-transform: capitalize;
  }
}

.deleted-secure-documents-text-container {
  margin-bottom: 20px;
  .header-text {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .description-text {
    font-size: 15px;
    font-weight: 400;
  }
}

.edit-document {
  width: auto;
  height: 30px;
}

.document-icon-container {
  width: 100%;
  height: 30px;
  background-color: transparent;

  .aula-icon {
    font-size: 20px;
  }

  &[data-warning] {
    color: var(--color-alert);
  }
}

.document-export-status {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-primary-darker);
  font-size: 14px;
  font-weight: 400;
  min-width: 120px;
}

.table-cell.check {
  width: 40px;
  padding: 0 12px 0 2px !important;
  .custom-checkbox {
    padding: 4px;
    margin-bottom: 0;
    left: 30px;
    top: -3px;
  }
}
.table-cell.actions {
  .custom-checkbox {
    margin-bottom: 20px;
    left: -12px;
  }
}

.upload-link {
  cursor: pointer;
  float: left;
  margin-top: 12px;
}
.submit-row {
  margin-top: 15px;
}
.modalSharedWith {
  .row {
    .col,
    .col-1,
    .col-2,
    .col-4 {
      padding: 10px 10px 5px 10px;
      border-bottom: solid 1px #eee;
    }
    .text {
      padding-top: 13px;
    }
    .check {
      text-align: center;
      label {
        margin-right: 0;
      }
    }
    .delete {
      text-align: right;
      padding-top: 14px;
    }
  }
}

.badge-notification.documents {
  --position: unset;
  margin-inline-end: 10px;
  float: left;

  /deep/ .aula-badge {
    width: 18px;
    height: 18px;
    i:before {
      content: '';
    }
  }
}
.table-cell.collapsed-only /deep/ .badge {
  position: relative;
  top: 9px;
  left: 30px;
}
.drawer-content .aula-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 40px;
  top: -12px;
}
.show-more {
  padding: 20px 0;
  text-align: center;
  button {
    float: none;
  }
}
.modalSharedWith /deep/ .el-input input {
  background-color: $color-grey-light;
}

.table-controls {
  position: relative;
  display: flex;
}

.sort {
  margin-top: auto;
}

.tooltip {
  div {
    text-align: left !important;
  }
}

.choose-document {
  @include breakpoint-lg-down() {
    left: -10px !important;
  }
}

.filetype {
  color: $color-primary-dark-guardian;
  .theme-employee & {
    color: $color-primary-dark-employee;
  }
}

.mobile-left {
  @include breakpoint-lg-down() {
    width: 100%;
  }
}
/deep/ .table {
  overflow-wrap: anywhere;
  .table-row {
    &.header {
      &[data-sticky] {
        position: sticky;
        top: 176px;
        z-index: 2;

        &[data-padding-top] {
          top: 236px;
        }
      }
      &[data-parent-type='administration'] {
        font-size: 15px;
        font-weight: 700;
      }
    }

    &.mobile-header {
      display: table-row;
      position: sticky;
      top: 106px;
      z-index: 2;
      background-color: var(--color-grey-dark);

      .table-cell {
        display: table-cell;
        padding: 15px;
        border-right: solid 2px transparent;
        border-top: solid 2px var(--color-grey-light);
        border-bottom: solid 2px var(--color-grey-light);
        .custom-checkbox {
          left: 4px;
        }
      }

      @include breakpoint-lg() {
        top: 94px;
      }

      @include breakpoint-xl() {
        display: none;
      }
    }
  }
  .table-row:focus {
    position: relative;
  }
  .table-cell {
    .dropdown-select {
      .dropdown-menu {
        max-width: 210px !important;

        .disabled-drop-down .dropdown-item.disabled {
          background-color: transparent;
        }
      }
    }
  }
}
.no-documents {
  width: 100%;
  margin: 20px 0;
  font-weight: bold;
}

.documents-secure-list-container {
  @include breakpoint-lg-down() {
    padding-bottom: var(--menu-item-height);
  }
  @include breakpoint-sm-down {
    .aula-search-outer {
      margin-bottom: 4px;
    }
  }
}

.marking-text {
  color: $color-alert;
}
.alert-text {
  color: $color-alert;
}
.multiple-file-actions {
  clear: both;
  margin-left: -10px;
  margin-bottom: 10px;
  overflow: auto;

  @include breakpoint-xl-down() {
    .btn {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.sort {
  &.asc::before,
  &.desc::before {
    margin-left: 3px !important;
    margin-right: -3px !important;
    color: unset !important;
  }
}

.header-actions-container {
  &[data-sticky] {
    @include breakpoint-xl() {
      position: sticky;
      top: 58px;
      z-index: 3;
      background-color: var(--color-grey-light);
      padding-top: 8px;
      padding-bottom: 2px;
    }
  }
}

.secure-files-filter-container {
  display: flex;
  align-items: center;
  background-color: var(--color-grey-light);
  &[data-sticky] {
    position: sticky;
    z-index: 3;
    top: 60px;
    min-height: 48px;

    @include breakpoint-lg() {
      min-height: 36px;
      top: 60px;
    }

    @include breakpoint-xl() {
      top: 142px;

      &[data-padding-top] {
        top: 202px;
      }
    }
  }

  .dropdown-filter-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    .filter-dropdown {
      --dropdown-max-width: 240px;

      .filter-arrow {
        --font-size: 0.4rem;
        margin-left: 5px;
      }

      .dropdown-item {
        .active-filter-icon {
          position: absolute;
          left: 9px;
          top: 16px;
          --font-size: 10px;
          color: var(--color-primary-base);
          opacity: 0.8;
        }

        &:hover {
          .active-filter-icon {
            opacity: 1;
          }
        }
      }
    }
  }
  .selected-document-count {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 2px;
  }
}
