


















































@import '../../../shared/assets/scss/core/breakpoints.scss';

.document-preview-table-variables {
  --color-base: var(--color-grey-base);
  --border-color-top: transparent;
  --border-color-right: transparent;
  --border-color-bottom: transparent;
  --border-color-left: transparent;
  --table-btn-link-decoration: none;
}

.document-preview-table {
  padding-top: 10px;

  @include breakpoint-sm-down() {
    .mobile-hidden {
      display: none;
    }
  }

  .document-preview-body {
    --color-base: var(--color-white);

    @include breakpoint-lg-down() {
      /deep/ .table-cell {
        padding: 12px;
      }

      /deep/.action-table-cell {
        padding: 12px 0 0 0;
        max-width: 40px;
      }
    }

    @include breakpoint-sm-down() {
      /deep/.mobile-hidden {
        display: none;
      }

      /deep/.document-title-attachment-container {
        min-width: 80px;

        .attachment-list-container .attachment-list .media-item .media-name {
          max-width: 64px;
        }
      }
    }
  }
}
