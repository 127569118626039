





















































































@import '../../../shared/assets/scss/core/breakpoints.scss';

.menu-item {
  .menu-text {
    text-transform: capitalize;
    .early-student & {
      color: #fff !important;
      text-transform: capitalize;
      font-family: Baloo, serif;
      font-weight: normal;
    }
  }
  /deep/ .badge-notification {
    .badge span {
      .early-student & {
        top: -6px !important;
        left: -1px !important;
      }
    }
    @include breakpoint-lg-down() {
      .early-student & {
        margin-top: -2px;
        margin-left: 3px;
      }
      .badge span {
        .early-student & {
          left: 0 !important;
        }
      }
    }
  }
}
