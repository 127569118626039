







































































































































































































































































































































































































































































































































































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.reported-content-container {
  .b-table {
    th:first-child,
    td:first-child {
      font-weight: normal;
    }
  }
  tbody tr {
    &:hover {
      button {
        color: $color-white;
      }
    }
  }
}

.preview-container {
  min-width: 400px;
  padding-bottom: 5px;
  /deep/ .card-text {
    padding-left: 25px;
  }

  /deep/ video {
    width: 800px;
  }
}

.aula-close {
  right: 20px;
  position: absolute;
  top: 0;
  cursor: pointer;
}
