








































.input-group.invalid {
  --input-group-border-width: 1px;
  --input-group-border-style: solid;
  --input-group-border-color: var(--color-alert);
}
.input-group-prepend {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--input-background-color, var(--color-white));
  gap: 0.5rem;
  padding: 0 0.5rem;
  margin-right: unset;
}
i.icon {
  font-size: 0.75rem;
  &.icon-Aula_search {
    font-size: 1.25rem;
  }
}
