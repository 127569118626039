




































































































































































.icon-container {
  background-color: transparent;

  .aula-icon {
    font-size: 16px;
    color: var(--color-primary-darker);
  }
}

.show-extra-data-button {
  width: 100%;
  display: flex;
  justify-content: center;

  &[data-is-collapsed] {
    .icon-container .aula-icon {
      transform: rotate(90deg);
    }
  }
}

.document-title-attachment-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 180px;
  max-width: 200px;

  .align-middle {
    align-items: center;
  }

  .document-title-container {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .icon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-height: 24px;
      border-radius: unset;
    }
  }

  .attachment-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .attachment-list {
      --background-color: var(--color-grey-base);
      --thumbnail-background-color: var(--color-white);

      /deep/.media-data-container .album-media-data .media-item {
        .media-name {
          max-width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: unset;
          margin-left: unset;
          padding-left: 8px;
          font-weight: unset;
        }

        .media-detail-button {
          display: none;
        }

        .media-thumbnail {
          width: 24px;
          height: 24px;

          .icon-Aula_note {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.document-description {
  min-width: 100px;
}

.regarding-children-container {
  min-width: 84px;
}

.remove-document {
  width: 100%;
  display: flex;
  justify-content: center;
}
