



























































































































.sending-documents-metadata {
  color: var(--color-primary-darker);
}
